import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigation"
export default class extends Controller {
  connect() {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.getElementById('navbarNav');
    const sidebarBackdrop = document.querySelector('.sidebar-backdrop');
    const closeSidebarBtn = document.querySelector('.close-sidebar-btn');
    const navLinks = document.querySelectorAll('.navbar-nav .nav-link');

    navbarToggler.addEventListener('click', function () {
      const isCollapsed = navbarCollapse.classList.contains('show');
      if (isCollapsed) {
        sidebarBackdrop.classList.remove('show');
      } else {
        sidebarBackdrop.classList.add('show');
      }
    });

    closeSidebarBtn.addEventListener('click', function () {
      navbarCollapse.classList.remove('show');
      sidebarBackdrop.classList.remove('show');
    });

    sidebarBackdrop.addEventListener('click', function () {
      navbarCollapse.classList.remove('show');
      sidebarBackdrop.classList.remove('show');
    });

    navLinks.forEach(link => {
      link.addEventListener('click', function () {
        navbarCollapse.classList.remove('show');
        sidebarBackdrop.classList.remove('show');
      });
    });
  }
}
