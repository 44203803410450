import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';

// Connects to data-controller="swiper"
export default class extends Controller {
  connect() {
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 8500,
        disableOnInteraction: false,
      },
      slidesPerView: 4,
      spaceBetween: 10,
      centeredSlides: true,
      maxBackfaceHiddenSlides: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1170: {
          slidesPerView: 4,
        },
        1366: {
          slidesPerView: 4,
        },
        1600: {
          slidesPerView: 4,
        },
        1920: {
          slidesPerView: 3,
        },
      }
    });
  }
}
