// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"


import AOS from 'aos';




document.addEventListener('turbo:load', () => {
  AOS.init();

  // const scrollLinks = document.querySelectorAll('a[data-behavior="scroll-top"]');
  // scrollLinks.forEach(link => {
  //     link.addEventListener('click', function() {
  //         window.scrollTo(0, 0);  // Scroll to top of the page
  //     });
  // });

    //Maya Payment Modal 
      var paymentModal = document.getElementById('paymentModal');
      var paymentForm = document.getElementById('payment_form');
      
      paymentModal.addEventListener('shown.bs.modal', function () {
        var backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.classList.add('custom-backdrop');
        }
      });
    
      paymentModal.addEventListener('hidden.bs.modal', function () {
        var backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.classList.remove('custom-backdrop');
        }
        paymentForm.reset();
        window.history.pushState({}, '', '/');
      });


      if (window.location.pathname === "/payment") {
        // Assuming you are using Bootstrap's modal and it has an ID of "paymentModal"
        const paymentModal = new bootstrap.Modal(document.getElementById("paymentModal"));
        paymentModal.show();
      }


        // Attach click event to all anchor links with href starting with "#"
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
          anchor.addEventListener("click", function (e) {
            e.preventDefault();

            // Get the target element by extracting the ID from the anchor's href
            const targetId = this.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
              // Scroll to the element with a smooth effect and align it to the top
              window.scrollTo({
                top: targetElement.offsetTop,
                behavior: "smooth"
              });

              // Update the URL in the address bar without jumping
              window.history.replaceState(null, null, `#${targetId}`);
            }
          });
        });
      
});


