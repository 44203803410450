import Clipboard from '@stimulus-components/clipboard';

export default class extends Clipboard {
  connect() {
    super.connect();
  }

  copy(event) {
    const title = event.target.getAttribute('data-clipboard-text');

    navigator.clipboard.writeText(title).then(() => {
      this.copied();
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  copied() {

  }
}
