import { Controller } from "@hotwired/stimulus";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export default class extends Controller {
  connect() {
 
    this.initSplide5();
    this.initSplide2();
    this.initSplide3();
    this.initSplide4();
    this.initSplide1();
    this.initSplide6();

  }

  initSplide5() {
    const slide5 = document.querySelector('.splide5');
    if(slide5){
    const splide = new Splide(slide5, {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      perPage: 8,
      autoScroll: {
        speed: 1,
      },
      pagination: false,
      arrows: false,
      breakpoints: {
        1200: {
          perPage: 7,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 6,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 5,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 4,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }
  }

  

  initSplide2() {
    const slide2 = document.querySelector('.splide2');
    if(slide2){
    const splide = new Splide(slide2, {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      perPage: 8,
      autoScroll: {
        speed: 0.5,
      },
      pagination: false,
      arrows: false,
      breakpoints: {
        1200: {
          perPage: 7,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 6,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 5,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 4,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }
  }
  initSplide3() {
    const slide3 = document.querySelector('.splide3');
    if(slide3){
    const splide = new Splide(slide3, {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      perPage: 8,
      autoScroll: {
        speed: 1,
      },
      pagination: false,
      arrows: false,
      breakpoints: {
        1200: {
          perPage: 7,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 6,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 5,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 4,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }
  }
  initSplide4() {
    const slide4 = document.querySelector('.splide4');
    if(slide4){
    const splide = new Splide(slide4, {
      type: 'loop',
      gap: 10,
      drag: 'free',
      focus: 'center',
      perPage: 3,
      autoScroll: {
        speed: 1,
      },
      autoWidth: true,
      pagination: false,
      arrows: false,
   
      breakpoints: {
        1200: {
          perPage: 2,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 2,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 2,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 1,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }
}

  initSplide1() {
    const slide1 = document.querySelector('.splide1');
    if(slide1){
    const splide = new Splide(slide1, {
      type: 'loop',
      gap: 10,
      drag: 'free',
      focus: 'center',
      perPage: 2,
      autoScroll: {
        speed: 1,
      },
     
      pagination: false,
      arrows: false,
      breakpoints: {
        1200: {
          perPage: 2,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 1,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 1,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 1,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }

  
  }

  initSplide6() {
    const slide6 = document.querySelector('.splide6');
    if(slide6){
    const splide = new Splide(slide6, {
      type: 'loop',
      gap: 10,
      drag: 'free',
      focus: 'center',
      perPage: 4,
      autoScroll: {
        speed: 1,
      },
      autoWidth: true,
      pagination: false,
      arrows: false,
      breakpoints: {
        1200: {
          perPage: 3,  // For screens smaller than 1200px, show 4 slides per page
        },
        992: {
          perPage: 3,  // For screens smaller than 992px, show 3 slides per page
        },
        768: {
          perPage: 2,  // For screens smaller than 768px, show 2 slides per page
        },
        576: {
          perPage: 1,  // For screens smaller than 576px, show 1 slide per page
        },
      },
    });

    splide.mount({ AutoScroll });
  }

}
  
}
