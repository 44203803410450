import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["collapse"]; // Define targets for each accordion item

  connect() {
    // Ensure Bootstrap's collapse functionality is initialized for exclusive opening
    this.collapseTargets.forEach((collapseEl) => {
      new Collapse(collapseEl, {
        toggle: false, // Initialize without toggling immediately
      });
    });
  }
}