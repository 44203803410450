import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["background"];

  connect() {
    window.addEventListener("scroll", this.moveBackground.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.moveBackground.bind(this));
  }

  moveBackground() {
    const scrollPosition = window.scrollY;
    const viewportWidth = window.innerWidth;

    if (this.element.classList.contains('luzon-directory')) {
      const backgroundPositionX = -(scrollPosition / 3) + (viewportWidth / 25);
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    } else if (this.element.classList.contains('visayas-directory')) {
      const backgroundPositionX = (scrollPosition / 3) + (viewportWidth / 25 - 500);
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    } else if (this.element.classList.contains('mindanao-directory')) {
      const backgroundPositionX = -(scrollPosition / 3) - viewportWidth + 2200;
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    }

    if (this.element.classList.contains('luzon-map')) {
      const backgroundPositionX = -(scrollPosition / 3) + (viewportWidth / 25) + 650;
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    } else if (this.element.classList.contains('visayas-map')) {
      const backgroundPositionX = (scrollPosition / 3) + (viewportWidth / 25 - 500) - 650;
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    } else if (this.element.classList.contains('mindanao-map')) {
      const backgroundPositionX = -(scrollPosition / 3) - viewportWidth + 2850;
      this.backgroundTarget.style.backgroundPosition = `${backgroundPositionX}px center`;
    }
  }
}
