import { Controller } from "@hotwired/stimulus"
import LazyLoad from "vanilla-lazyload"

// Connects to data-controller="lazyload"
export default class extends Controller {
  static targets = ["lazy"]
  connect() {
    this.initializeLazyLoad();
    // this.listenForTurboStreamUpdates();
  }

  initializeLazyLoad() {
    this.lazyLoad = new LazyLoad({
      elements_selector: ".lazy",
      threshold: 300,
      load_delay: 100
    });
  }

  listenForTurboStreamUpdates() {
    document.addEventListener("turbo:frame-load", () => {
      this.refreshLazyLoad();
    });
  }

  refreshLazyLoad() {
    if (this.lazyLoad) {
      this.lazyLoad.update(); // Re-scan the DOM for lazy-loaded elements
    }
  }
}
