import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "spinningImage"];

  connect() {
    // this.spinAnimation();
    // window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    // window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  // showDropdown() {
  //   this.dropdownTarget.classList.add("show");
  // }

  // hideDropdown() {
  //   this.dropdownTarget.classList.remove("show");
  // }

  // spinAnimation() {
  //   const spinningImages = this.spinningImageTargets;
  //   let animationPlayed = false;

  //   const handleScroll = () => {
  //     if (animationPlayed) {
  //       window.removeEventListener("scroll", handleScroll);
  //       return;
  //     }

  //     const windowHeight = window.innerHeight;

  //     spinningImages.forEach((image) => {
  //       const rect = image.getBoundingClientRect();

  //       // Check if the image is in the viewport
  //       if (rect.top >= 0 && rect.bottom <= windowHeight) {
  //         image.classList.add("spinning");
  //         animationPlayed = true;
  //       } else {
  //         image.classList.remove("spinning");
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);
  // }

  // handleScroll() {
  //   const scrollToTopButton = document.getElementById('scrollToTop');
  //   // if (scrollToTopButton) {
  //   //   scrollToTopButton.classList.toggle("d-none", window.scrollY <= 200);
  //   // }

  //   if (window.scrollY > 200) {
  //     scrollToTopButton.classList.remove("d-none");
  //   } else {
  //     scrollToTopButton.classList.add("d-none");
  //   }
  // }
}
