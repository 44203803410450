
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="partner"
export default class extends Controller {
  connect() {
    // const logoWrappers = document.querySelectorAll('#logo-wrapper, #logo-wrapper-2, #logo-wrapper-3, #logo-wrapper-4, #logo-wrapper-5, #logo-wrapper-6');
    // logoWrappers.forEach(logoWrapper => {
    //   const originalLogos = logoWrapper.innerHTML; // Save original content
    //   let duplicatedContent = '';
    //   for (let i = 0; i < 3; i++) { // Duplicate 3 times
    //     duplicatedContent += originalLogos;
    //   }
    //   logoWrapper.innerHTML = duplicatedContent; // Set all content at once

    //   // Calculate and set width to accommodate all images
    //   const logos = logoWrapper.querySelectorAll('img'); // Select all images
    //   const totalWidth = logos.length * (logos[0].offsetWidth + parseInt(getComputedStyle(logos[0]).marginRight)); // Calculate width
    //   logoWrapper.style.width = `${totalWidth}px`; // Set the width
    // });
  }
}
