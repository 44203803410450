// app/javascript/controllers/glightbox_controller.js

import { Controller } from "@hotwired/stimulus";
import GLightbox from "glightbox";

export default class extends Controller {
  static values = { selector: String }; // Define selector as a configurable value

  connect() {
    const selector = this.selectorValue || ".glightbox";

    // Initialize GLightbox with only the selector and necessary options
    this.lightbox = GLightbox({
      selector: selector,
      touchNavigation: true,
      loop: true,
      closeButton: true,
    });
  }
}
